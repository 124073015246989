import React from 'react';
import { Box, Container, Typography } from '@mui/material';

export default function PrivacyPolicy(params)
{
    return (
        <Container maxWidth="md">

            <Typography color="primary" textAlign="center" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                Privacy Policy
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Thank you for using War Organ. This Privacy Policy explains how the application handles and uses the limited data it collects to provide you with the best user experience.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Data Collection
            </Typography>
            <Typography >
                A. Crash Reports: The application may collect crash reports automatically to identify and resolve technical issues. These reports may include device information but do not contain any personally identifiable information.
            </Typography>
            <Typography >
                B. Dropbox Credentials: The application may request Dropbox credentials to enable integration with Dropbox services. Please note that your Dropbox credentials are stored locally on your device and are not transmitted or stored on any external server or cloud service.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Data Usage
            </Typography>
            <Typography >
                A. Crash Reports: The crash reports collected are used solely for debugging and improving the application's performance. They are not shared with any third parties.
            </Typography>
            <Typography >
                B. Dropbox Credentials: Your Dropbox credentials are used only to establish a connection with Dropbox services on your local machine, allowing you to access and manage your files using the application. The credentials are not transmitted or accessible to the developer in any way.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Data Security
            </Typography>
            <Typography >
                Reasonable measures are taken to protect your data from unauthorized access, loss, alteration, or disclosure. However, it is essential to understand that no method of data transmission or storage is entirely secure. While efforts are made to protect your data, the application cannot guarantee its absolute security.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Third-Party Links
            </Typography>
            <Typography >
                The application may contain links to third-party websites or services, such as Dropbox. Please be aware that this Privacy Policy applies only to the application and not to any third-party websites or services. Users are encouraged to review the privacy policies of any third-party services before using them.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Children's Privacy
            </Typography>
            <Typography >
                The application is not intended for use by children under the age of 13. The developer does not knowingly collect personal information from individuals under 13 years of age. If a parent or guardian believes that a child has provided personal information, they are requested to contact the developer, and the information will be promptly removed from the records.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Changes to the Privacy Policy
            </Typography>
            <Typography sx={{ mb: 5 }}>
                The Privacy Policy may be updated from time to time to reflect changes in practices or for other operational, legal, or regulatory reasons. The most current version of the policy will be available within the application.
            </Typography>

        </Container>

    );
}
