import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Container, Typography, Alert } from '@mui/material';
import axios from "axios";

const FeedbackForm = () => {
    const [alertInfo, setAlertInfo] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState('');

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = () => {

        const dto = { feedback: feedback, senderEmail: email };
        axios.post('api/feedback/submit', dto).then(res => {
            setFeedback('');
            setEmail('');
            setAlertInfo({ type: "success", message: "Feedback submitted, thanks!" });
        })
        .catch(error => {
            console.log(error);
            setAlertInfo(
                {
                    type: "error",
                    message: "Feedback submission failed, sorry! Feedback may also be sent to warorgan@gmail.com"
                });
        });
    };

    return (
        <Container sx={{ mb: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }} maxWidth="lg">
            <TextField
                label="Feedback"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={feedback}
                onChange={handleFeedbackChange}
                margin="normal"
            />

            <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                margin="normal"
            />

            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                Email not required, but if you're submitting a bug report or feature request and you'd like to be informed on the progress or timeline, please include an email address.
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: '16px' }}
            >
                Submit
            </Button>

            {alertInfo && <Alert sx={{ mt: 2 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}

        </Container>
    );
};

export default FeedbackForm;
