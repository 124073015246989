import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Toolbar, Typography, Container, Button } from '@mui/material';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import WarOrganIcon from '../Resources/WarOrgan256.png';

export default function ResponsiveAppBar(params)
{
    const navigate = useNavigate();

    return (
        <AppBar sx={{ height: { xs: 150, md: 90 } }} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/*Title logo*/}
                    <Box>
                        <Box sx={{ flexGrow: 2, alignItems: 'center', display: 'inline-flex', cursor: "normal", backgroundColor: "transparent", border: 0 }} >
                            <Box component="img" src={WarOrganIcon} sx={{ width: "64px", mr: 2, mt: 1 }} />
                            <Box>
                                <Typography style={{
                                    userSelect: 'none',  // Prevent text selection
                                    WebkitUserSelect: 'none', // For Safari
                                    MozUserSelect: 'none', // For older versions of Firefox
                                }} component="h1" variant="h3" noWrap>War Organ</Typography>
                                <Typography style={{
                                    userSelect: 'none',  // Prevent text selection
                                    WebkitUserSelect: 'none', // For Safari
                                    MozUserSelect: 'none', // For older versions of Firefox
                                }} component="h6" variant="subtitle2" noWrap>A 40k 10th edition list building application</Typography>
                            </Box>
                        </Box>

                        {/*Page buttons mobile*/}
                        <Box sx={{ height: 30, display: { xs: 'flex', md: 'none' } }}>
                            <Button component={Link} to="" sx={{ color: 'white', display: 'block' }}>
                                Downloads
                            </Button>
                            <Button component={Link} to="feedback" sx={{ color: 'white', display: 'block' }}>
                                Feedback
                            </Button>
                            <Button component={Link} to="release-notes" sx={{ color: 'white', display: 'block' }}>
                                Release Notes
                            </Button>
                        </Box>
                        <Box sx={{ height: 30, display: { xs: 'flex', md: 'none' } }}>
                            <Button component={Link} to="privacy-policy" sx={{ color: 'white', display: 'block' }}>
                                Privacy Policy
                            </Button>
                        </Box>
                        
                    </Box>
                    
                    {/*Page buttons*/}
                    <Box sx={{ mt: 6, ml: 3, height: 30, display: { xs: 'none', md: 'flex' } }}>
                        <Button component={Link} to="" sx={{ color: 'white', display: 'block' }}>
                            Downloads
                        </Button>
                        <Button component={Link} to="feedback" sx={{ color: 'white', display: 'block' }}>
                            Feedback
                        </Button>
                        <Button component={Link} to="release-notes" sx={{ color: 'white', display: 'block' }}>
                            Release Notes
                        </Button>
                        <Button component={Link} to="privacy-policy" sx={{ color: 'white', display: 'block' }}>
                            Privacy Policy
                        </Button>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}