import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/Home';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReleaseNotes from './components/ReleaseNotes'; 
import { Box } from '@mui/material';
import FeedbackForm from './components/FeedbackForm';

export default function App() {

    const darkMode = true;
    const theme = createTheme({
        palette:
        {
            mode: darkMode ? "dark" : "light",
            primary: { main: darkMode ? "#6364D0" : "#6364d0" },
            secondary: { main: darkMode ? "#6364D0" : "#6364D0" }
        },
        typography: { "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif` }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ResponsiveAppBar />

            <Box sx={{ marginTop: '40px' }}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/release-notes' element={<ReleaseNotes />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/feedback' element={<FeedbackForm />} />
                </Routes>
            </Box>

        </ThemeProvider>
    )
}
