import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import axios from "axios";

export default function ReleaseNotes(params)
{
    const [markdownContent, setMarkdownContent] = useState('');

    const getReleaseNotes = async () =>
    {
        const result = await axios.get('api/downloads/release-notes');
        setMarkdownContent(result.data.notes);
    };

    useEffect(() => {
        getReleaseNotes();
    }, []);

    return (
        <Container sx={{ mb: 6 }} maxWidth="md">

            <Typography color="primary" textAlign="center" component="h1" variant="h4" sx={{ fontWeight: 'bold', mb:2 }}>
                Release Notes
            </Typography>

            <ReactMarkdown>{markdownContent}</ReactMarkdown>

         </Container>
    );
}
